import * as styles from './background.module.css'
import classNames from 'classnames'

type Props = {
  isHomepage?: boolean,
}

const Background = ({ isHomepage = false }: Props) =>
  <div className={classNames(styles.background, { [styles.homepage]: isHomepage })} />
{/* <picture className={styles.backgroundPicture}>
  <source
    media='(orientation: landscape)'
    srcSet='https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200.jpg 1200w,
            https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.jpg 2400w'
    sizes='100vw'
  />
  <source
    media='(orientation: portrait)'
    srcSet='https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360.jpg 360w,
            https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360@2x.jpg 720w'
    sizes='100vw'
  />
  <img src='https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.jpg 2400w' alt='Person writing out a greeting card' />
</picture> */}

  // <img
  //   className={classNames(styles.background, { [styles.homepage]: isHomepage })}
  //   srcSet={
  //     `
  //     https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360@2x.jpg 360px
  //     https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360@2x.jpg 360px

  //     `
  //   }
  // />

export default Background
