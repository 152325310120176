import { logEvent } from '../../utilities/analytics'
import classnames from 'classnames'
import Background from 'components/Background'
import AccountMenu from 'components/AccountMenu'
import Logo from 'components/Logo'
import Footer from 'components/Footer'
import NoSSR from 'react-no-ssr'
import defaultStyles from './page.module.css'
import { useEffect } from 'react'
import UsOnlyWarning from 'components/UsOnlyWarning'
import TwoCirclesSpinner from 'components/Spinner/TwoCirclesSpinner'
import SocialLinks from 'components/SocialLinks'
import PageTitle from 'components/PageTitle'
import PageDescription from 'components/PageDescription'
import useAnalyticsEvent, { AnalyticsEvent, AnalyticsPayload } from 'hooks/useAnalyticsEvent'

interface PageProps {
  children: JSX.Element[] | JSX.Element | String,
  description?: string,
  isLoading?: boolean,
  loadingText?: string,
  title?: string,
  titleAnalytics?: string,
  noSSR?: boolean
  styles?: {
    content?: string,
    hero?: string,
    logo?: string,
  },
  width?: 'default' | 'wide' | 'narrow' | 'blog' | 'homepage',
  bodyContent?: JSX.Element[] | JSX.Element | String,
  suppressAccountMenu?: boolean,
  analyticsEvent?: AnalyticsEvent,
  analyticsPayload?: AnalyticsPayload,
}

const Page = ({ children, title = 'CardHero', titleAnalytics, description, width = 'default', noSSR = false, styles, bodyContent, suppressAccountMenu = false, isLoading = false, loadingText = 'Loading...', analyticsEvent, analyticsPayload }: PageProps) => {
  useEffect(() => {
    logEvent(`Page load: ${titleAnalytics || title}`)
  }, [])
  useAnalyticsEvent(analyticsEvent, analyticsPayload)
  const combinedStyles = { ...defaultStyles, ...styles }
  return <>
    <PageTitle>{ title }</PageTitle>
    <PageDescription>{ description }</PageDescription>
    <NoSSR>
      <UsOnlyWarning />
    </NoSSR>
    <Background isHomepage={width === 'homepage'} />
    <div className={classnames(combinedStyles.content, {
      [defaultStyles.wideContent]: width === 'wide',
      [defaultStyles.homepageContent]: width === 'homepage',
      [defaultStyles.blogContent]: width === 'blog',
      [defaultStyles.narrowContent]: width === 'narrow',
    })}>
      <div className={combinedStyles.hero}>
        <div className={defaultStyles.heading}>
          <div className={combinedStyles.logo}>
            <Logo />
          </div>
          { !suppressAccountMenu && <div className={defaultStyles.accountMenu}><AccountMenu /></div> }
        </div>
        { isLoading
          ? <TwoCirclesSpinner label={loadingText} />
          : noSSR
            ? <NoSSR>{ children }</NoSSR>
            : children
        }
      </div>
      { bodyContent }
      <Footer />
      <SocialLinks />
    </div>
  </>
}

export default Page
