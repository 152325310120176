import { isNil, isEmpty } from 'lodash'
import { useUser } from 'modules/User/remote'
import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as config from '../shared/config'

export const DEFAULT_POST_LOGIN_PAGE = '/my/bundles'

export type Role = 'guest' | 'user' | 'admin'
export type RoleValue = 0 | 1 | 10

const ROLES = {
  guest: 0,
  user: 1,
  admin: 10,
}

export const getValueForRoleLabel = (roleLabel: Role) => ROLES[roleLabel]

export const isGuestUser = (role: number) => role === ROLES.guest
export const isFullUser = (role?: number | undefined | null) => role && [ROLES.user, ROLES.admin].includes(role)
export const isAdmin = (role: number) => role === ROLES.admin

export const useNonAdminRedirect = () => {
  const { user, isUserLoading } = useUser()
  const router = useRouter()
  const role = user?.role
  // console.log('USE NON-ADMIN REDIRECT', isLoaded, role)
  useEffect(() => {
    if (user && !isUserLoading && !isNil(user?.role) && !isAdmin(user.role)) {
      // console.log('admins only!')
      router.push('/')
    }
  }, [isUserLoading, role])
}

export const useOnConfirmedRedirect = () => {
  // console.log('USE ON CONFIRMED REDIRECT')
  const { user } = useUser()
  const router = useRouter()
  const startPage = config.isWaitingListOn
    ? undefined
    : isEmpty(user?.orders) ? '/start/who' : '/home'
  const isConfirmed = user?.isConfirmed
  // console.log({ isConfirmed })
  useEffect(() => {
    if (isConfirmed) {
      const { redirectTo } = Router.query
      console.log('redirect to', redirectTo, startPage)
      const pushToString = redirectTo as string || startPage as string
      const pushTo = getUrlFromString(pushToString) || pushToString
      if (pushTo) {
        router.push(pushTo)
      }
    }
  }, [isConfirmed, startPage])
}

const getUrlFromString = (urlString: string) => {
  try {
    return new URL(urlString)
  } catch (_) {
    return ''
  }
}

const usTimezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Boise',
  'America/Detriot',
  'Pacific/Honolulu',
  'America/Adak',
  'America/Anchorage',
  'America/Juneau',
  'America/Nome',
  'America/Menominee',
  'America/Metlakatla',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
]

export const isInUnitedStates = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // console.log({ timezone })
  return usTimezones.includes(timezone)
}
