import { isFunction, upperFirst } from 'lodash'
import classNames from 'classnames'
import * as styles from './button.module.css'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import TwoCirclesSpinner from '../Spinner/TwoCirclesSpinner'

export type ButtonProps = {
  children: React.ReactNode,
  className?: string,
  onClick?: (e: Event | undefined) => void | Event,
  variant?: 'tiny' | 'small' | 'medium' | 'large' | 'smallText',
  type?: 'button' | 'submit' | 'reset',
  isDisabled?: boolean,
  isSecondary?: boolean,
  handleActionCompleted?: (setEnabled: Dispatch<SetStateAction<boolean>>) => void,
  color?: 'red' | 'blue',
  isNoWrap?: boolean,
}

const Button = ({ children, className, onClick, handleActionCompleted, variant = 'medium', type = 'button', isDisabled = false, isSecondary = false, color = 'red', isNoWrap = false, ...buttonProps }:ButtonProps) => {
  const [isActivated, setIsActivated] = useState(false)
  const handleClick = (e:any) => {
    setIsActivated(true)
    return isFunction(onClick) && onClick(e)
  }
  useEffect(() => {
    if (isFunction(handleActionCompleted)) {
      handleActionCompleted(setIsActivated)
    }
  }, [setIsActivated, handleActionCompleted])
  return <button
    {...buttonProps}
    onClick={isFunction(onClick) ? handleClick : undefined}
    className={classNames(styles[variant], className, {
      [styles.secondary]: isSecondary,
      [styles.isDisabled]: isDisabled,
      [styles.isActivated]: isActivated,
      [styles[`buttonColor${upperFirst(color)}`]]: color !== 'red',
      [styles.buttonNoWrap]: isNoWrap,
    })}
    disabled={isDisabled || isActivated}
    type={type}
  >
    { children }
    { isActivated && <div className={styles.backgroundSpinner}>
      <div className={styles.backgroundSpinnerInner}>
        <TwoCirclesSpinner scale={1} />
      </div>
    </div>}
  </button>
}

export default Button
