import { isArray, isNull } from 'lodash'

export type ObjectType = Record<string, unknown>
export type KeyValueStringStringPair = [string, string]
export type KeyValueStringUnknownPair = [string, unknown]
type CallbackFunction = (value: [string, unknown], index: number, array: [string, unknown][]) => any

export const mapObject = (obj: ObjectType, fn: CallbackFunction): ObjectType => {
  const mappedObjectEntries =
    Object
      .entries(obj)
      .map(fn)
  return Object.fromEntries(mappedObjectEntries)
}

type UnknownCallbackFunction = (value: unknown) => unknown
type MapUnknownDeepOptions = {
  triggerFn?: (value: unknown) => boolean
}
export const mapUnknownDeep = (unk: unknown, fn: UnknownCallbackFunction, options?: MapUnknownDeepOptions): unknown => {
  console.log('mUD', unk)
  const isValueUndefined = typeof unk === 'undefined'
  const isTriggered = !isValueUndefined && options?.triggerFn && options.triggerFn(unk)
  const isValueNull = !isValueUndefined && isNull(unk)
  console.log({ isValueUndefined, isTriggered, isValueNull })
  if (isValueUndefined || isTriggered || isValueNull) {
    return fn(unk)
  }
  if (isArray(unk)) {
    return unk.map(u => mapUnknownDeep(u, fn, options))
  }
  if (unk && typeof unk === 'object') {
    return mapObject(unk as ObjectType, ([k, v]) => {
      console.log('Deep Object: ', { k, v })
      return [k, mapUnknownDeep(v, fn, options)]
    }
    )
  }
  return fn(unk)
}
