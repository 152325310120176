import { truncate, first, isUndefined, unescape } from 'lodash'

export const smartTruncate = (stringToTruncate: string, length: number) => truncate(stringToTruncate, {
  length,
  'separator': /,?\.? +/
});

export const startsWithVowel = (stringToCheck: string) => {
  if (isUndefined(stringToCheck)) {
    return false
  }
  ['a', 'e', 'i', 'o', 'u'].includes(first(stringToCheck.split('')) as string)
}

export const unescapeTitle = (stringToDecode: string) => {
  const partiallyDecodedString = unescape(stringToDecode)
  const decodedString = partiallyDecodedString.replace(
    '&nbsp;', ' '
  )
  return decodedString
}

export const pluralize = (str: string, count: number, pluralSuffix = 's') =>
  Boolean(count === 1)
    ? str
    : str + pluralSuffix
