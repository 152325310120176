import TwoCirclesSpinner from 'components/Spinner/TwoCirclesSpinner'
import styles from './loadingContent.module.css'

type Props = {
  label?: string,
}

const LoadingContent = ({ label = 'Loading...' }: Props) =>
  <div className={styles.loadingContent}>
    <TwoCirclesSpinner label={label} />
  </div>

export default LoadingContent
