import Head from 'next/head'
import { unescapeTitle } from 'utilities/text'

type Props = {
  children: string | undefined,
}

const PageDescription = ({ children }: Props) => children
  ? <Head>
    <meta name='description' content={unescapeTitle(children)}/>
  </Head>
  : null

export default PageDescription
