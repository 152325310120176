import { useState } from 'react'
import * as Sentry from '@sentry/react'
import Button from 'components/Button'
import LinkButton from '../LinkButton'
import styles from './accountMenu.module.css'
import CartButton from '../CartButton'
import Router from 'next/router'
import Icon from 'components/Icons/Icon'
import Clickable from 'components/Clickable'
import { isFullUser } from 'utilities/user'
import { useUser } from 'modules/User/remote'
// import { useCart } from 'modules/Orders/remote'

// interface AccountMenuProps {
//   isSignedIn: boolean
//   onSignOutUser: Function
//   onFetchUser: Function
// }

const AccountMenu = () => {
  const { user } = useUser()

  if (user?.id) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id,
      })
    })
  }

  const isSignedIn = !!user?.id && isFullUser(user?.role)
  // useEffect(() => {
  //   dispatch(userActions.fetchUser())
  // }, [])
  // useEffect(() => {
  //   isSignedIn && dispatch(orderActions.getCart())
  // }, [isSignedIn])
  const onLogout = () => {
    // const pathname = window.location.pathname === '/' ? '' : window.location.pathname
    // onSignOutUser().then(() => { window.location.href = window.location.href.replace(pathname, '') })
    // dispatch(userActions.signOutUser())
    Router.push('/logout')
  }
  const [isExpanded, setIsExpanded] = useState(false)
  return <div className={styles.accountMenu}>
    <div className={styles.accountMenuButton}>
      <Clickable onClick={() => { setIsExpanded(!isExpanded) }}>
        <Icon icon='hamburger' isActive={isExpanded} />
      </Clickable>
    </div>
    <Clickable onClick={() => setIsExpanded(false)}>
      <div className={isExpanded ? styles.backdropOn : styles.backdropOff } />
    </Clickable>
    <div className={isExpanded ? styles.accountMenuContentExpanded : styles.accountMenuContent}>
      { !!isSignedIn && <div className={styles.actionItem}>
        <CartButton />
      </div> }
      <div className={styles.actionItemNoIcon}>
        { isSignedIn
          ? <Button
              variant='smallText'
              onClick={onLogout}
              className={styles.fullWidthButton}
            >Log out</Button>
          : <LinkButton
              variant='smallText'
              href='/login'
              className={styles.fullWidthButton}
            >Log in</LinkButton>
        }
      </div>
      <div className={styles.actionItemNoIcon}>
        <LinkButton
          variant='smallText'
          href='/about'
          className={styles.fullWidthButton}
        >About</LinkButton>
      </div>
      <div className={styles.actionItemNoIcon}>
        <LinkButton
          variant='smallText'
          href='/trees'
          className={styles.fullWidthButton}
        >Trees</LinkButton>
      </div>
      <div className={styles.actionItemNoIcon}>
        <LinkButton
          variant='smallText'
          href='/faqs'
          className={styles.fullWidthButton}
        >FAQs</LinkButton>
      </div>
      <div className={styles.actionItemNoIcon}>
        <LinkButton
          variant='smallText'
          href='/support'
          className={styles.fullWidthButton}
        >Support</LinkButton>
      </div>
    </div>
  </div>
}

// AccountMenu.mapDispatchToProps = {
//   onSignOutUser: ,
// }

// export default connect(AccountMenu)
export default AccountMenu
