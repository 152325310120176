import styles from './twoCirclesSpinner.module.css'
import { colorRed } from 'styles/colors.module.css'

type TwoCirclesSpinnerProps = {
  scale?: number,
  label?: string,
  color?: string,
}

const TwoCirclesSpinner = ({ scale = 1, label, color = colorRed }: TwoCirclesSpinnerProps) =>
  <div className={styles.twoCirclesContainer} style={{ transform: `scale(${scale})`}}>
    <div className={styles.twoCircles} style={{ color }}>
      <div></div>
      <div></div>
    </div>
    { label && <div className={styles.label}>{ label }</div> }
  </div>

export default TwoCirclesSpinner
