import Link from 'next/link'
import styles from './linkButton.module.css'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

type Props = {
  children: React.ReactNode,
  className?: string,
  variant?: 'tiny' | 'small' | 'medium' | 'large' | 'smallText',
  color?: 'red' | 'blue',
  href: string,
  isSecondary?: boolean,
  isDisabled?: boolean,
  noWrap?: boolean,
}

const LinkButton = ({
  href,
  children,
  className = '',
  variant = 'large',
  isSecondary = false,
  isDisabled = false,
  color = 'red',
  noWrap = false,
}: Props) =>
  <Link href={href}>
    <a className={classNames(styles[variant], className, {
      [styles.secondary]: isSecondary,
      [styles.isDisabled]: isDisabled,
      [styles.buttonNoWrap]: noWrap,
      [styles[`buttonColor${upperFirst(color)}`]]: color !== 'red',
    })}>
      {children}
    </a>
  </Link>

export default LinkButton
