import { isInUnitedStates } from 'utilities/user'
import * as styles from './usOnlyWarning.module.css'

const UsOnlyWarning = () => {
  if (!isInUnitedStates()) {
    return <div className={styles.usOnlyWarning}>Please note that CardHero currently ships only within the United States</div>
  }
  return null
}

export default UsOnlyWarning
