import { isNumber } from 'lodash'
import axios from 'axios'
import { addToCartPayload, updateCardCountPayload, OrderTaxesPayload, SetPromoCodeForOrderPayload } from './interfaces'
import { createAddressRequestDto } from '../../../api/src/user/dto/create-address.dto'
import { fetcher, get, post } from 'utilities/api'
import useSWR from 'swr'
import { Order } from '../../../api/src/order/order.entity'
import { CartDetails } from '../../../api/src/order/types'

axios.defaults.withCredentials = true

export const useCart = () => {
  const { data, error, mutate } = useSWR<Order>('/order/cart', fetcher)

  return {
    cart: data,
    mutateCart: mutate,
    isCartLoading: !error && !data,
    isCartError: error,
  }
}

export const useOrder = (orderId: number) => {
  const { data, error, mutate } = useSWR<Order>(`/order/${orderId}/details`, fetcher)

  return {
    order: data,
    mutateOrder: mutate,
    isOrderLoading: !error && !data,
    isOrderError: error,
  }
}

export const useCartDetails = (cartId?: number) => {
  const { data, error } = useSWR<CartDetails>(cartId ? `/order/cartCode/${cartId}` : null, fetcher)

  return {
    cartDetails: data,
    isCartDetailsLoading: !error && !data,
    isCartDetailsError: error,
  }
}

export const useUnfilledOrders = () => {
  const { data, error } = useSWR<Order[]>('/order/unfilled', fetcher)
  return {
    unfilledOrders: data,
    isUnfilledOrdersLoading: !error && !data,
    isUnfilledOrdersError: error,
  }
}

export const useOrders = () => {
  const { data, error } = useSWR<Order[]>('/order', fetcher)
  return {
    orders: data,
    isOrdersLoading: !error && !data,
    isOrdersError: error,
  }
}





// type MakeCardOrders = {
//   relationId: number,
//   occasions: string[],
// }

// export const makeCardOrders = ({
//   relationId,
//   occasions,
// }: MakeCardOrders) =>
//   post('/orders', {
//     relationId,
//     occasions,
//   })

type OccasionIds = {
  occasionIds: number[],
}

type AddBundleToCartPayload = {
  bundleId: number,
  limitToOccasionIds?: number[],
}

export const addCardCreditsToCart = ({ occasionIds }: OccasionIds) => {
  post('/order/credit', {
    occasionIds,
  })
}

export const addCardToCart = (payload: addToCartPayload) =>
  post('/order', { cardOrders: [payload] })

export const addCardsToCart = (payload: addToCartPayload[]) =>
  post('/order', { cardOrders: payload })

export const addBundleToCart = ({ bundleId, limitToOccasionIds }: AddBundleToCartPayload) =>
  post('/order/bundle', { bundleId, limitToOccasionIds })


export const fetchCart = () =>
  axios({
    method: 'get',
    url: `${window.cardhero.settings.API_BASE}/order/cart`,
  })

export const fetchCartCode = (cartId: number) =>
  !!cartId
    ? axios({
      method: 'get',
      url: `${window.cardhero.settings.API_BASE}/order/cartCode/${cartId}`,
    })
    : Promise.reject('Cart ID not provided')

export const fetchOrders = () =>
    axios({
      method: 'get',
      url: `${window.cardhero.settings.API_BASE}/order`
    })

export const fetchOrderById = (orderId: number) =>
  !!orderId
    ? axios({
      method: 'get',
      url: `${window.cardhero.settings.API_BASE}/order/${orderId}/details`,
    })
    : Promise.reject('Cart ID not provided')

export const fetchUnfilledOrders = () =>
  get('/order/unfilled')

export const updateCardCountForCart = (payload: updateCardCountPayload) =>
  post('/order/count', payload)

export const fetchOrderProcessingStatus = (orderId: number) => {
  return isNumber(orderId)
    ? axios({
      method: 'get',
      url: `${window.cardhero.settings.API_BASE}/order/status/${orderId}`,
    })
    : Promise.reject('Order ID is required')
}

export const updateOrderWithTaxes = (orderId: number, payload: OrderTaxesPayload) =>
  post(`/order/${orderId}/tax`, { state: payload.state })

  // export const fetchTaxInCents = (payload: taxInCentsPayload) =>
//   get('/order/taxInCents', { params: { subtotalCents: payload.subtotalInCents }})

export const setAddressForOrder = ({ address, orderId }: { address: createAddressRequestDto, orderId: number }) => {
  console.log({ address, orderId })
  return post(`/order/${orderId}/address`, address)
  // return isNumber(orderId)
  //   ? post(`/order/${orderId}/address`, address)
  //   // ? axios({
  //   //   method: 'post',
  //   //   url: `${window.cardhero.settings.API_BASE}/order/${orderId}/address`,
  //   //   data: address,
  //   // })
  //   : Promise.reject('Order ID is required')
}

export const setPromoCodeForOrder = ({ orderId, promoCode }: SetPromoCodeForOrderPayload) => {
  console.log({ promoCode, orderId })
  return post(`/order/${orderId}/promoCode`, {
    code: promoCode,
  })
}

export const processFreeCard = ({ orderId }: { orderId: number }) => {
  console.log({ orderId })
  return post(`/order/${orderId}/processFreeCard`)
}
