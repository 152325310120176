import styles from './socialLinks.module.css'
import Icon from 'components/Icons/Icon'

const SocialLinks = () => <div className={styles.socialLinks}>
  <div className={styles.socialLink}>
    <a
      target='social'
      href='https://www.instagram.com/thecardherosavestheday/'
      title='Visit us on Instagram'
    >
      <Icon icon='instagram' />
    </a>
  </div>
  <div className={styles.socialLink}>
    <a
      target='social'
      href='https://www.twitter.com/cardheroai/'
      title='Visit us on Twitter'
    >
      <Icon icon='twitter' />
    </a>
  </div>
  <div className={styles.socialLink}>
    <a
      target='social'
      href='https://www.facebook.com/CardHero-105564331577690'
      title='Visit us on Facebook'
    >
      <Icon icon='facebook' />
    </a>
  </div>
</div>

export default SocialLinks
