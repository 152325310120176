import useSWR from 'swr'
import { get, post, fetcher, HttpStatusCodes } from 'utilities/api'
import { throttle } from 'lodash'
import * as types from './types'
import { User as UserEntityType } from '../../../api/src/user/user.entity'

export const useUser = () => {
  const { data, error, mutate } = useSWR<types.UserType, Error>('/user', fetcher)

  return {
    user: data,
    isUserLoading: !error && !data,
    isUserError: error,
    mutateUser: mutate,
  }
}

export const useUsers = () => {
  const { data, error } = useSWR<UserEntityType[]>('/user/all', fetcher)
  return {
    users: data,
    isUsersLoading: !error && !data,
    isUsersError: error,
  }
}






const occasionallySendConfirmationToUser = throttle(() => {
  return post('/user/confirmation-message')
}, 3000, { trailing: false })

export const sendConfirmationToUser = () =>
  occasionallySendConfirmationToUser()

export const confirmUser = ({ userId, confirmationCode, isStayLoggedIn }: types.ConfirmUserType) =>
  post('/user/confirmed', {
    userId,
    confirmationCode,
    isStayLoggedIn,
  })

const occasionallyCreateUser = throttle(payload => {
  return post('/user', payload)
}, 5000, { trailing: false })

export const createUser = (payload: any) => {
  // return Promise.resolve()
  return occasionallyCreateUser(payload)
}

const occasionallyCreateTempUser = throttle(() => {
  return post('/user/temp')
}, 5000, { trailing: false })

export const createTempUser = () =>
  occasionallyCreateTempUser()

export const loginUser = (payload: any) =>
  post('/user/login', payload)

export const loginConfirmUser = (payload: types.LoginConfirmUserType) =>
  post('/user/login/confirm', payload)

export const fetchUser = () =>
  get('/user')

export const saveAddress = (payload: types.SaveAddressType) =>
  post('/address', payload)

export const signOutUser = () =>
  get('/auth/logout')

export const fetchUsers = () =>
  get('/user/all')

export const sendSmsMessage = (payload: types.SendSmsMessageType) =>
  post('/messaging/sms/outgoing', payload)

// export const fetchWaitlistCount = () => get('/user/waitlistCount')
