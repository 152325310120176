import axios, { AxiosResponse } from 'axios'
import { mapObject } from './object'
import { getConfig } from '../config/envVars'
import { isArray } from 'lodash'
const config = getConfig()

const API_BASE = config.apiBase
console.log({ API_BASE })

declare global {
  interface Window { cardhero: any; }
}

type ObjectType = Record<string, unknown>

const checkValueForDate = (value: unknown): unknown => {
  if (typeof value === 'undefined') {
    return value
  }
  if (isArray(value)) {
    // console.log('IS ARRAY', value)
    const arrayWithValuesAsDates = value.map(v => checkValueForDate(v))
    return arrayWithValuesAsDates
  }
  if (value && typeof value === 'object') {
    // console.log('IS OBJECT', value)
    const objectWithDateValues = mapObject(
      value as ObjectType,
      ([key, value]) =>
        [key, checkValueForDate(value)],
    )
    // console.log({ objectWithDateValues })
    return objectWithDateValues
  }
  if (typeof value === 'string') {
    // console.log('IS STRING', value)
    const dateValue = Date.parse(value)
    if (!isNaN(dateValue)) {
      const date = new Date(dateValue)
      const year = date.getFullYear()
      if (year && year < 2100 && year > 1900) {
        console.log({ year })
        return date
      }
    }
  }
  return value
}

const mapResponseTypes = (response: AxiosResponse) => {
  const body = response.data
  if (body === null || body === undefined || typeof body !== 'object') {
    return body
  }
  // console.log({ bodyType: typeof body, body })
  const bodyWithDates = checkValueForDate(body)
  return {
    ...response,
    data: bodyWithDates,
  }
}

axios.defaults.withCredentials = true
// const mapObjectValuesToDates = () => {
//   mapObject(body, ([key, value]) => {
//     console.log({ value })
//     if (typeof value === 'string') {
//       console.log('VALUE IS STRING', value)
//       const dateValue = Date.parse(value)
//       console.log('VALUE DATE', dateValue)
//       if (!isNaN(dateValue)) {
//         return [key, new Date(dateValue)]
//       }
//     }
//     if (typeof value === 'object') {

//     }
//     return [key, value]
//   })
// }

axios.interceptors.response.use(mapResponseTypes)

interface GetOptions {
  params: { [key: string]: string | number }
}

export const get = (path: string, options?: GetOptions) => {
  // console.log('GET', `${window.cardhero.settings.API_BASE}${path}`)
  return axios({
    method: 'get',
    url: `${API_BASE}${path}`,
    params: options?.params || undefined,
  })
}

export const post = (path: string, payload?: Object) => {
  // console.log('GET', `${window.cardhero.settings.API_BASE}${path}`)
  return axios({
    method: 'post',
    url: `${API_BASE}${path}`,
    data: payload,
  })
}

export const patch = (path: string, payload?: Object) => {
  // console.log('GET', `${window.cardhero.settings.API_BASE}${path}`)
  return axios({
    method: 'patch',
    url: `${API_BASE}${path}`,
    data: payload,
  })
}

// For use with SWR
export const fetcher = (urlPath: string) => axios.get(`${API_BASE}${urlPath}`).then(res => res.data)
// export const fetcher = (urlPath: string) => fetch(`${API_BASE}${urlPath}`).then(r => r.json())

export const HttpStatusCodes = {
  SUCCESS: 200,
  CREATED: 201,
}

export const isSuccessful = (response: AxiosResponse) => {
  return Boolean(response.status < 300)
}
