import Link from 'next/link'
import * as styles from './footer.module.css'

const Footer = () => {
  const now = new Date()
  return <footer className={styles.footer}>
    <div className={styles.footerRow}>
      <Link href='/feedback'>
        <a className={styles.linkEmphasized}>Feedback please!</a>
      </Link>
      <Link href='/terms'>
        <a className={styles.link}>Terms of Use</a>
      </Link>
      <Link href='/privacy'>
        <a className={styles.link}>Privacy Policy</a>
      </Link>
    </div>
    <div className={styles.footerRow}>
      <Link href='/about'>
        <a className={styles.link}>About</a>
      </Link>
      <Link href='/faqs'>
        <a className={styles.link}>FAQs</a>
      </Link>
      <Link href='/blog'>
        <a className={styles.link}>Blog</a>
      </Link>
      <Link href='/trees'>
        <a className={styles.link}>Trees</a>
      </Link>
      <Link href='/support'>
        <a className={styles.link}>Support</a>
      </Link>
    </div>
    <div className={styles.footerRow}>&copy; Modern Bit and Pixel 2018 - { now.getFullYear() }</div>
  </footer>
}

export default Footer
