// import Icon from 'components/Icons/Icon'
import Link from 'next/link'
// import useTimeout from 'hooks/useTimeout'
import styles from './logo.module.css'
// import { useState } from 'react'
// import classNames from 'classnames'

const Logo = () => {
  // const [hasTimeElapsed, setHasTimeElapsed] = useState(false)
  // useTimeout(() => {
  //   setHasTimeElapsed(true)
  // }, 3000)
  // console.log({ hasTimeElapsed })
  return <Link href='/'>
    <a className={styles.logo}>
      {/* <svg viewBox="0 0 453 453" width={30} className={styles.logoImage}>
        <filter id="shadow" x="0" y="0" width="200%" height="200%">
          <feDropShadow dx="40" dy="40" stdDeviation="35" floodColor="#ff0000" floodOpacity="1" />
        </filter>
        <path fill='#ee5c5f' d="M306,181c120.4,0,218,97.6,218,218S426.4,617,306,617,88,519.4,88,399,185.6,181,306,181Z" transform="translate(-79.5 -172.5)"/>
        <path fill='#e0565a' d="M305.5,608c-115.43,0-209-93.57-209-209s93.57-209,209-209" transform="translate(-79.5 -172.5)" />
        <path fill='#f7c63f' d="M158,269V527.6L309,580l144-52.4V269L309,282.1Z" transform="translate(-79.5 -172.5)" />
        <path fill='#e5b83c' d="M309,580,158,527.6V269l151,13.1" transform="translate(-79.5 -172.5)"/>
        <path fill='#ffffff' d="M257.78,418l90.34-99-31,86.77,40.45,20.33L267.22,527.8,296.88,441Z" transform="translate(-79.5 -172.5)"/>
        <path fill='#f5f5f5' d="M307,483l-39.8,44.79L296.88,441,257.78,418l49.07-53.75Z" transform="translate(-79.5 -172.5)"/>
      </svg> */}
      <div className={styles.logoText}>
        <span className={styles.uppercaseTitle}>CardHero</span>
        <span className={styles.tmLabel}>℠</span>
      </div>
      {/* <div className={styles.betaLabel}>beta</div> */}
      {/* <Icon className={styles.} icon='logo' /> */}
    </a>
  </Link>
}

export default Logo
