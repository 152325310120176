import { isNull } from 'lodash'
import Icon from '../Icons/Icon'
import styles from './cartButton.module.css'
import Link from 'next/link'
import { Order } from '../../../api/src/order/order.entity'
import { useCart } from 'modules/Orders/remote'
import LoadingContent from 'components/LoadingContent'

const getOrderCardCount = (cart: Order) => {
  if (!((cart?.status && cart?.status !== 'succeeded') || isNull(cart?.status))) {
    return 0
  }
  return cart.cardOrders.reduce((sum, cardOrder) => sum + cardOrder.count, 0)
}

const getOrderCardCountLabel = (orderCardCount: number) => {
  if (orderCardCount === 0) {
    return <div className={styles.cartEmptyLabel}>Empty</div>
  }
  return `${orderCardCount} card${orderCardCount === 1 ? '' : 's'}`
}

const CartButton = () => {
  const { cart } = useCart()
  // if (!((cart?.status && cart?.status !== 'succeeded') || isNull(cart?.status))) {
  //   return null
  // }
  if (!cart) {
    return <LoadingContent label='Loading cart...' />
  }
  const orderCardCount = getOrderCardCount(cart)
  return <div className={styles.cartButton}>
    <Link href='/cart'>
      <a className={styles.cartLink}>
        <div className={styles.cartIcon}>
          <Icon icon='cart' />
        </div>
        <div className={styles.cartLabel}>Cart: { getOrderCardCountLabel(orderCardCount) }</div>
      </a>
    </Link>
  </div>
}

export default CartButton
