import { usePlausible } from 'next-plausible'
import { useEffect } from 'react'

export type AnalyticsPayload = {
  props: Record<string, unknown>,
}
export type AnalyticsEvent =
  'Page View: Rating Card for Occasion' |
  'Page View: Choosing Card for Occasion' |
  'Start' |
  'Ordering' |
  'Order Completed' |
  'Order Failed' |
  undefined

const useAnalyticsEvent = (key: AnalyticsEvent, payload?: AnalyticsPayload) => {
  const plausible = usePlausible()
  useEffect(() => {
    if (key) {
      if (payload) {
        plausible(key, payload)
      } else {
        plausible(key)
      }
    }
  }, [key, payload])
}

export default useAnalyticsEvent
